import React from 'react';
import '../css/projects.css';


const projectData = [
    {
        name: "RustPlus.py",
        description: "RustPlus.py is my library for interacting with the Rust+ API built into Rust dedicated servers through Python.",
        image: require('./images/rustplus.png'),
        technology: ["Protobuf", "Python", "WebSockets", "Async & Threading"],
        link: "https://github.com/olijeffers0n/rustplus",
    },
    {
        name: "Pathetic",
        description: "Pathetic is a performant Pathfinding library for Spigot & Paper Minecraft Plugins I co-created and help maintain.",
        image: require('./images/pathetic.png'),
        technology: ["Java", "A* Pathfinding"],
        link: "https://github.com/patheloper/pathetic",
    },
    {
        name: "MySchedule.py",
        description: "This is an API Wrapper I have written to allow the automatic retrieval of Shift and Clock data from the McDonald's Schedule System.",
        image: require('./images/myschedule.png'),
        technology: ["Python", "HTTP Requests", "HTML Parsing"],
        link: "https://github.com/olijeffers0n/MySchedule.py",
    },
];

function Projects() {
    return (
        <section className="projects">
            <h2>Projects</h2>
            <div className={"projects-container"}>
                {projectData.map((project) => (
                    <div className="project-card-wrapper">
                        <div className="project-card">
                            <a href={project.link}>
                                <img src={project.image} alt={project.name}/>
                            </a>
                            <div className="project-info">
                                <a href={project.link}>
                                    <h3>
                                        <strong>{project.name}</strong>
                                    </h3>
                                </a>
                                <p>{project.description}</p>
                            </div>
                            <div className="technology-cards">
                                {project.technology.map((technology) => (
                                    <span>{technology}</span>
                                ))}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
}

export default Projects;

import React from 'react';

const BackgroundPattern: React.FC = () => {

    function parallax() {
        const s = document.getElementById("background-parallax");
        if (s === null) {
            return;
        }
        const yPos = window.scrollY / 40;
        s.style.top = -yPos + "%";
    }

    if (typeof window !== 'undefined') {
        window.addEventListener("scroll", function(){
            parallax();
        });
    }

    return (
        <div id={"background-parallax"} className="background-pattern" />
    );
};

export default BackgroundPattern;

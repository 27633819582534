import React from 'react';
import {FaDiscord, FaGithub} from "react-icons/fa";

function Home() {
    return (
        <div className="home">
            <h1 className={"title"}>Hi i'm Ollie 👋</h1>
            <p className={"subtitle"}>Open Source Developer & CS Undergraduate</p>
            <div className="social-links">
                <a href="https://github.com/olijeffers0n" target="_blank" rel="noopener noreferrer">
                    <FaGithub/>
                </a>
                <a href="https://discordapp.com/users/392347881648029696" target="_blank" rel="noopener noreferrer">
                    <FaDiscord/>
                </a>
            </div>
        </div>
    );
}

export default Home;

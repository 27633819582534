import React from 'react';
import '../css/about-me.css';

function AboutMe() {
    return (
        <section className={"about-me"}>
            <h2>About Me</h2>
            <div className={"about-me-text-container"}>
                <p>I'm Ollie, a Computer Science undergraduate and open source developer.</p>
                <p>I'm passionate about open source software and I'm always looking for new things to work on.</p>
                <p>You may see me around - In addition to my work, I contribute to projects
                    like <a href={'https://github.com/PaperMC'}><strong>PaperMC</strong></a> where I am part of the triage team.
                </p>
            </div>
            <div className="skills">
                <h3>Skills</h3>
                <ul>
                    <li>Python</li>
                    <li>Java</li>
                    <li>React</li>
                    <li>JavaScript</li>
                    <li>HTML/CSS</li>
                    <li>Git</li>
                    <li>SQL</li>
                </ul>
            </div>
        </section>
    );
};

export default AboutMe;
